import React from "react";
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import {applyMiddleware, createStore} from 'redux';

import {logger} from "redux-logger";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./scss/falcon.scss";
import "./css/falcon.css";
import "./css/fontawesome-free/css/all.min.css";
// import '@fortawesome/fontawesome-free/css/all.css';
import Home from "./components/Home";
import CpeaTutoring from "./components/pages-components/CpeaTutoring";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

//ADD ERROR COMPONENT

export default class App extends React.Component {
  render() {
    return(
          <Router>
              <switch>
                  <Route path="/" exact component={Home}/>
                  <Route path="/cpeaTutoring" component={CpeaTutoring} />
              </switch>
          </Router>
    );
  }
}

