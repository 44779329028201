import React from "react";
 import ReactDOM from "react-dom";

 export default class Signup extends React.Component {
   render() {
     return(

    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">

            <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
            <h2 className="text-white mb-5">Contact us</h2>


            <div className="row">
                <div className="col-md-6">
                    <input type="email" className="form-control flex-fill mr-0 mr-sm-2 mb-3 form-styling" id="firstName" placeholder="First name"/>
                </div>
                <div className="col-md-6">
                    <input type="email" className="form-control flex-fill mr-0 mr-sm-2 mb-3 form-styling" id="lastName" placeholder="Last name"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <input type="email" className="form-control flex-fill mr-0 mr-sm-2 mb-3 form-styling" id="subject" placeholder="Subject"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <input type="email" className="form-control flex-fill mr-0 mr-sm-2 mb-3 form-styling" id="inputEmail" placeholder="Enter email address..."/>
                </div>
            </div>
              <div className="row">
                  <div className="col-md-12">
                      <textarea type="message" className="form-control flex-fill mr-0 mr-sm-2 mb-3 form-styling" id="message" placeholder="Message..."/>
                  </div>
              </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="submit" className="btn btn-primary mx-auto w-100">Send</button>
                </div>
            </div>

          </div>
        </div>
      </div>
    </section>
     )
   }
 }






