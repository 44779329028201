import React, {useState, useEffect, useRef} from "react";
import ExternalIcon from "./ExternalIcon";
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

const DropdownMenu = ({title, links}) => {
const [showMenu, setShowMenu] = useState(false);
const ref = useRef()

useEffect(() => {
  const onBodyClick = event => {
    if (ref.current && ref.current.contains(event.target)) {
      return
    }
    setShowMenu(false)
  }
  document.body.addEventListener("click", onBodyClick)
  return () => {
    document.body.removeEventListener("click", onBodyClick)
  }
}, [])

const linkList = links.map((link) => 
!link.external ? <Link key={link.title} class="dropdown-item" to={link.path}>{link.title}</Link> : 
<Link key={link.title} class="dropdown-item" to={{ pathname: link.path }}  target="_blank" >{link.title} <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
  <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
</svg> </Link>
);

const toggleMenu = () => {
    setShowMenu(!showMenu);
}

    return (
        <li className="nav-item dropdown">
        <a onClick={toggleMenu} className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {title}
        </a>
        {showMenu && (
            <div className="dropdown-menu show" aria-labelledby="navbarDropdownMenuLink">
              {linkList}
            </div>
          )
        }
      </li>
    );
};

export default DropdownMenu;