import React from "react";
import ReactDOM from "react-dom";
import focus1 from "../img/carousel/focus1.jpg";
import focus2 from "../img/carousel/focus2.jpg";
import focus3 from "../img/carousel/focus3.jpg";

export default class ValuesSection extends React.Component {
    render() {
        return(
            <React.Fragment>

                <section id="values-section" className="projects-section bg-light">
                    <div className="container">
                        <h2 className="mb-4 text-center">Our focus</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div>
                                    <img className="img-fluid" src={focus1} alt=""/>
                                    <div className="bg-primary text-center h-100 project">
                                        <div className="d-flex h-100">
                                            <div className="project-text w-100 my-auto text-center text-lg-left">
                                                <h4 className="text-white">Forming Partnerships</h4>
                                                <p className="mb-0 text-white-50">Connect with organization and individuals whose aim and focus aligns with ours to benefit students and institutions in and around the region</p>
                                                <hr className="d-none d-lg-block mb-0 ml-0 border-gray"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img className="img-fluid" src={focus2} alt=""/>
                                    <div className="bg-primary text-center h-100 project">
                                        <div className="d-flex h-100">
                                            <div className="project-text w-100 my-auto text-center text-lg-left">
                                                <h4 className="text-white">Learning Opportunities</h4>
                                                <p className="mb-0 text-white-50">Collaborating with facilitators and institutions that bring beneficial support to our students who need to overcome their academic challenges</p>
                                                <hr className="d-none d-lg-block mb-0 ml-0 border-gray"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img className="img-fluid" src={focus3} alt=""/>
                                    <div className="bg-primary text-center h-100 project">
                                        <div className="d-flex h-100">
                                            <div className="project-text w-100 my-auto text-center text-lg-left">
                                                <h4 className="text-white">Student friendly</h4>
                                                <p className="mb-0 text-white-50">Create an environment where students are safe, able to have fun learning and be supported in non-tradition academic areas</p>
                                                <hr className="d-none d-lg-block mb-0 ml-0 border-gray"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </React.Fragment>
        )
    }
}





