import React, {Fragment, useState} from "react";
import ReactDOM from "react-dom";
import ScrollspyNav from "react-scrollspy-nav";
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

import CpeaTutoring from "./pages-components/CpeaTutoring";
import stemlogo from "../img/icons/stemlogo.png"
import DropdownMenu from "./form-components/DropDownMenu";


const Navigation = () => {

  const [showMobileNav, setShowMobileNav] = useState(false);

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };

  const tutoringLinks = [
    {title: "CPEA Tutoring", path: "#"},
    {title: "CSEC Tutoring", path: "#"},
    {title: "Adult Tutoring", path: "#"},
    {title: "STEM Courses", path: "#"},
  ];

  const progamLinks = [
    {title: "Online Tutoring", path: "#"},
    {title: "STEAM Robot Programs", path: "#"},
    {title: "STEAM Summer Programs", path: "#"},
    {title: "Hackathon", path: "#"},
    {title: "STEM Charity", path: "#"},
    {title: "ITEN project 2021", path: "https://stemsvg.org/enrol/index.php?id=99", external: true,}
  ];

  const aboutLinks = [
    {title: "Staff", path: "#"},
    {title: "STEM SVG", path: "#"},
    {title: "STEAM Summer Programs", path: "#"},
    {title: "Hackathon", path: "#"},
    {title: "STEM Charity", path: "#"},
  ];

  
  const elearningLinks = [
    {title: "Online Classes", path: "https://stemsvg.org/elearning/", external: true},
    {title: "Student Records", path: "https://studentrecords.stemsvg.org/index.php", external: true},
//     {title: "E-Finance", path: "https://my.waveapps.com/login/", external: true},
    {title: "Stem Online Library", path: "https://stemsvg.org/e-library/", external: true,},
    {title: "Email", path: "https://ds1370.tmddedicated.com:2096/webmaillogout.cgi", external: true,},
  ];

    const financePortalLinks = [
      {title: "Administration", path: "https://invoicing.co/#/", external: true},
      {title: "Online Academy (Clients)", path: "https://finstemsvg.invoicing.co/client/login" , external: true},
//       {title: "Online St. Lucia (Clients)", path: "https://stemslucia.invoicing.co/client/login", external: true},
//       {title: "Online St. Kitts (Clients)", path: "https://stemstkitts.invoicing.co/client/login", external: true,},
      {title: "Summer Program (Clients)", path: "https://stemsumcamp.invoicing.co/client/login", external: true,},
    ];

  

  return (
    <nav className={'navbar-shrink navbar navbar-expand-lg fixed-top'} id="mainNav">
    <div className="container">
        <img className="img-fluid logo navbar-brand" src={stemlogo} alt=""/>
        <button className="navbar-toggler navbar-toggler-right" onClick={toggleMobileNav} type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu &nbsp;&nbsp;
        <i className="fas fa-bars"></i>
      </button>
      <div className= {showMobileNav ? "show collapse navbar-collapse" : " " + 'collapse navbar-collapse'} id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="#signup">Contact</a>
          </li>

                <DropdownMenu title="Tutoring" links={tutoringLinks} />
                <DropdownMenu title="Programs" links={progamLinks} />
                <DropdownMenu title="About" links={aboutLinks} />
                <DropdownMenu title="E-Learning" links={elearningLinks} />
                 <DropdownMenu title="Billing" links={financePortalLinks} />
                
        </ul>
      </div>
    </div>
  </nav>
  )
}

export default Navigation;