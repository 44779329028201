import React from "react";
import ReactDOM from "react-dom";
import Navigation from "./Navigation";
import Carousel from "./Carousel";
import About from "./About";
import Signup from "./Signup"
import Footer from "./Footer";
import ValuesSection from "./ValuesSection";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/falcon.scss";
import "../css/falcon.css";
import "../css/fontawesome-free/css/all.min.css";



export default class Home extends React.Component {
    render() {
        return(
            <div>
                <Navigation/>
                <Carousel/>
                <About/>
                <ValuesSection/>
                <Footer/>
            </div>
        );
    }
}
