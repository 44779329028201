import React, {useState, useEffect, Component} from "react";
import ReactDOM from "react-dom";
import slider1 from '../img/carousel/slider1.jpg'
import slider2 from '../img/carousel/slider2.jpg'
import slider3 from '../img/carousel/slider3.jpg'
import slider4 from '../img/carousel/slider4.jpg'

class Carousel extends Component {

    constructor(props) {
      super(props);

      this.state = {
        counter: 1,
        slide1: "active",
        slide2: "",
        slide3: "",
        slide4: "",
        slide1Left: "",
        slide2Left: "",
        slide3Left: "",
        slide4Left: "",
      };

    }

    componentDidMount() {
        this.startCarousel();
    }

    carouselControlNext = () => {
        let nextCounter = this.state.counter;
        if(nextCounter != 4){
            nextCounter = nextCounter + 1;
        }else {
            nextCounter = 1;
        }
        this.setState({
            counter : nextCounter
        }, () => {
            this.activeIndicator();
        });
    }

    carouselControlPrev = () => {
        let prevCounter = this.state.counter;
        if(prevCounter != 1){
            prevCounter = prevCounter - 1;
        }else {
            prevCounter = 4;
        }
        this.setState({
            counter : prevCounter
        }, () => {
            this.activeIndicator();
        });
    }
    activeIndicator = () => {
        let currentIndicator = this.state.counter;
        if(currentIndicator == 1) {
           this.setState({
                slide1: "active",
                slide2: "",
                slide3: "",
                slide4: "",
           });
        }
        if(currentIndicator == 2) {
          this.setState({
               slide1: "",
               slide2: "active",
               slide3: "",
               slide4: "",
          });
        }
        if(currentIndicator == 3) {
         this.setState({
             slide1: "",
             slide2: "",
             slide3: "active",
             slide4: "",
         });
        }
        if(currentIndicator == 4) {
          this.setState({
              slide1: "",
              slide2: "",
              slide3: "",
              slide4: "active"
          });
         }
    }

    startCarousel = () => {
        this.intervalId  = setInterval(this.carouselControlNext, 5000);
    }

    stopCarousel = () => {
        clearInterval(this.intervalId);
    }

  render() {

    const slide1 = this.state.slide1;
    const slide2 = this.state.slide2;
    const slide3 = this.state.slide3;
    const slide4 = this.state.slide4;

    return(

        <div id="myCarousel" className="carousel slide" data-ride="carousel" onMouseLeave={this.startCarousel} onMouseEnter={this.stopCarousel}>
            <ol className="carousel-indicators">
              <li data-target="#myCarousel" ref={this.slide1} data-slide-to="0" className={slide1}></li>
              <li data-target="#myCarousel" ref={this.slide2} data-slide-to="1" className={slide2}></li>
              <li data-target="#myCarousel" ref={this.slide3} data-slide-to="2" className={slide3}></li>
              <li data-target="#myCarousel" ref={this.slide4} data-slide-to="2" className={slide4}></li>
            </ol>
            <div className="carousel-inner">
              <div className={slide1 + ' carousel-item' + ' slide1'}>
                <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
                    <rect className="slider-bg" width="100%" height="100%"></rect>
                </svg>
                <img class="d-block" src={slider1} alt="First slide" />
                <div className="container">
                  <div className="carousel-caption text-left">
                    <h1>STEAM Program</h1>
                    <p>Unlocking the future with possiblities by being a part of St. Vincent and the Grenadines premier summer program which is geared towards broadening horizons</p>
                    <p><a className="btn btn-lg btn-primary" href="#" role="button">Sign up today</a></p>
                  </div>
                </div>
              </div>

              <div className={slide2 + ' carousel-item' + ' slide2'}>
                <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
                    <rect className="slider-bg"  width="100%" height="100%"></rect>
                </svg>
                <img class="d-block" src={slider2} alt="First slide" />
                <div className="container">
                  <div className="carousel-caption text-left">
                    <h1>Fun while learning</h1>
                    <p>Hands-on learning experiences, curriculum-based programs, and a diverse range of activities bringing learning to life</p>
                    <p><a className="btn btn-lg btn-primary" href="#" role="button">Learn more</a></p>
                  </div>
                </div>
              </div>

              <div className={slide3 + ' carousel-item' + ' slide3'}>
              <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
                    <rect className="slider-bg"  width="100%" height="100%"></rect>
              </svg>
              <img class="d-block" src={slider3} alt="First slide" />
                <div className="container">
                  <div className="carousel-caption text-left">
                    <h1>Online Tutoring and Revision</h1>
                    <p>Collaborating with qualified facilitators across the region to provide assistance in all subject areas; and across all levels, including CPEA, CSEC and CAPE</p>
                    <p><a className="btn btn-lg btn-primary" href="#" role="button">Learn more</a></p>
                  </div>
                </div>
              </div>

              <div className={slide4 + ' carousel-item' + ' slide4'}>
              <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
                    <rect className="slider-bg"  width="100%" height="100%"></rect>
              </svg>
              <img class="d-block" src={slider4} alt="First slide" />
                <div className="container">
                  <div className="carousel-caption text-left">
                    <h1>STEM Learning Institute</h1>
                    <p>Support is offered for student and adult learners through partnership with other goal oriented organizations</p>
                    <p><a className="btn btn-lg btn-primary" href="#" role="button">Learn more</a></p>
                  </div>
                </div>
              </div>


            </div>
            <a className="carousel-control-prev" href="#myCarousel" role="button" onClick={this.carouselControlPrev} data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#myCarousel" role="button" onClick={this.carouselControlNext} data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
        </div>
    )
  }
}

export default Carousel;