import React from "react";
import ReactDOM from "react-dom";
import courses from "../img/icons/courses.png";
import puzzle from "../img/icons/puzzle.png";
import education from "../img/icons/education.png";
import handshake from "../img/icons/handshake.png";
import ScrollAnimation from 'react-animate-on-scroll';

export default class Header extends React.Component {
  render() {
    return(
        <section id="about" className="about-section  text-center">
          <div className="container">
              <div className="row">
                  <div className="col-lg-3">
                      <img src={education} className="about-icons" alt=""/>
                      <h3>Projects</h3>
                      <div className="about-content">
                          <p>STEMSVG has collaborated with different organizations to assist youths in diverse ways</p>
                      </div>
                      <p><a className="btn btn-primary" href="#" role="button">View details »</a></p>
                  </div>

                  <div className="col-lg-3">
                      <img src={puzzle} className="about-icons" alt=""/>
                      <h3>STEAM Program</h3>
                      <div className="about-content">
                        <p>One of the most hands-on program with its hallmark activity of introducing students to Robotics, 3D printing and much more</p>
                      </div>
                      <p><a className="btn btn-primary" href="#" role="button">View details »</a></p>
                  </div>

                  <div className="col-lg-3">
                      <img src={courses} className="about-icons" alt=""/>
                      <h3>Online Learning</h3>
                      <div className="about-content">
                        <p>Working in partnership with other organizations to achieve a common goal for our youths</p>
                      </div>
                      <p><a className="btn btn-primary" href="#" role="button">View details »</a></p>
                  </div>

                  <div className="col-lg-3">
                      <img src={handshake} className="about-icons" alt=""/>
                      <h3>Become a partner</h3>
                      <div className="about-content">
                        <p>We are open to working with other organization once your goal is beneficial to the youths</p>
                      </div>
                      <p><a className="btn btn-primary" href="#" role="button">View details »</a></p>
                  </div>

              </div>
          </div>
        </section>
    )
  }
}