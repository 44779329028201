import React from "react";
import Navigation from "../Navigation";
import Signup from "../Signup"
import Footer from "../Footer";

export default class CpeaTutoring extends React.Component {
    render() {
        return(
            <div>
                <Navigation/>
                <Signup/>
                <Footer/>
            </div>
        );
    }
}